import React, { useEffect, useState } from "react";
import {
  Button,
  ModalBody,
  ModalFooter,
  Container,
  Label,
  InputGroup,
  InputGroupAddon,
  Input,
  Progress,
  ModalHeader,
} from "reactstrap";
import {
  ClientExtender,
  ConntrackInfo,
  DeviceEntryReturn,
  Duration,
  EPCNameTags,
  ExitConnectionReturn,
  HardwareInfo,
  SensorReading,
  WifiDevice,
} from "../types";
import {
  display_version,
  OperatorActionProps,
  SuperUserOperatorActionProps,
} from "../utils";
import { we_are_superuser } from "../utils";
import SetWifiModal from "./setWifiActionsModal";
import WifiModal from "./WifiModal";
import { Card, Grid, Modal } from "@mui/material";
import MDBox from "components/MDBox";

function display_version_formatted(device: DeviceEntryReturn) {
  const version = display_version(device);
  return (
    <div>
      <Label>
        <b>Rita Version:</b>
      </Label>
      <InputGroup>
        <Input id="ritaVersion" readOnly value={version} />
      </InputGroup>
    </div>
  );
}

function get_conntrack(conntrack: ConntrackInfo | null) {
  if (conntrack === null) {
    return "There is no conntrack data to display";
  }

  const filled =
    conntrack.max_conns === 0
      ? 0
      : (conntrack.current_conns * 100) / conntrack.max_conns;
  return (
    <div>
      <Label>
        <b>Conntrack Data: </b>
      </Label>
      <div>Max Connections allowed: {conntrack.max_conns}</div>
      <div>Current Connections: {conntrack.current_conns}</div>
      <div className="text-center">
        {Math.floor(filled)}% of Max Connections
      </div>
      <Progress value={filled} />
    </div>
  );
}

function get_global_ipv6(exit_con: ExitConnectionReturn | null) {
  if (exit_con == null || exit_con.client_pub_ipv6 == null) {
    return "Device is not reporting a global ipv6 address!";
  }

  return exit_con.client_pub_ipv6;
}

function get_exit_info(exit_con: ExitConnectionReturn | null) {
  if (exit_con == null) {
    return <div>Device is not reporting exit info!</div>;
  }

  const cur_exit = exit_con.cur_exit;
  if (cur_exit == null || !cur_exit.cluster_name) {
    return <div>Device is not reporting exit info!</div>;
  } else {
    let instance_name;
    let instance_ip;
    if (!cur_exit.instance_name) {
      instance_name = "Device not reporting instance details";
    } else {
      instance_name = cur_exit.instance_name;
    }

    if (!cur_exit.instance_ip) {
      instance_ip = "Device not reporting instance details";
    } else {
      instance_ip = cur_exit.instance_ip;
    }

    return (
      <div>
        <Label>
          <b>Exit Info:</b>
        </Label>
        <InputGroup>
          <Input
            id="exitCluster"
            readOnly
            value={"Exit Cluster: " + cur_exit.cluster_name}
          />
          <Input
            id="exitName"
            readOnly
            value={"Instance Name: " + instance_name}
          />
          <Input id="exitIp" readOnly value={"Instance IP: " + instance_ip} />
        </InputGroup>
      </div>
    );
  }
}

function display_hardware(device: DeviceEntryReturn) {
  if (!device.last_hardware_info) {
    return "Device is not reporting hardware info";
  } else {
    const hw = device.last_hardware_info;
    let ethernet;
    if (!hw.ethernet_stats) {
      ethernet = "Device is not reporting any eth ports";
    } else {
      const obj = [];
      for (let i = 0; i < hw.ethernet_stats.length; i++) {
        const name = "eth" + i;
        const is_up = hw.ethernet_stats[i].is_up;
        const mode_of_operation = hw.ethernet_stats[i].mode_of_operation;
        const rx_errors = hw.ethernet_stats[i].rx_errors;
        const rx_packets = hw.ethernet_stats[i].rx_packet_count;
        const tx_errors = hw.ethernet_stats[i].tx_errors;
        const tx_packets = hw.ethernet_stats[i].tx_packet_count;
        obj.push(
          <div>
            <Label>
              <b>Eth{i}:</b>
            </Label>
            <InputGroup>
              <Input
                id={name}
                readOnly
                value={"Is Port Up: " + String(is_up)}
              />
              <Input id={name} readOnly value={mode_of_operation} />
              <Input
                id={name}
                readOnly
                value={
                  "TX Err/Pack: " + String(tx_errors) + "/" + String(tx_packets)
                }
              />
              <Input
                id={name}
                readOnly
                value={
                  "RX Err/Pack: " + String(rx_errors) + "/" + String(rx_packets)
                }
              />
            </InputGroup>
          </div>
        );
      }
      ethernet = obj;
    }
    let sensors;
    if (!hw.sensor_readings) {
      sensors = "Device is not reporting any sensors";
    } else {
      const obj = [];
      for (let i = 0; i < hw.sensor_readings.length; i++) {
        const name = "sensor" + i;
        const reading = Number(hw.sensor_readings[i].reading) / 1000 + "°C";
        obj.push(
          <div>
            <Label>
              <b>Sensor {hw.sensor_readings[i].name}:</b>
            </Label>
            <InputGroup>
              <Input id={name} readOnly value={reading} />
            </InputGroup>
          </div>
        );
      }
      sensors = obj;
    }

    const load_avg_string =
      hw.load_avg_one_minute +
      "/" +
      hw.load_avg_five_minute +
      "/" +
      hw.load_avg_fifteen_minute;
    const memory_string =
      Number(hw.allocated_memory) / 1000 +
      "MB / " +
      Number(hw.system_memory) / 1000 +
      "MB";

    const uptime_string = JSON.stringify(hw.system_uptime);
    const uptime_formatted = format_duration_string(uptime_string);

    const rita_uptime_string = JSON.stringify(device.rita_uptime);
    const rita_uptime_formatted = format_duration_string(rita_uptime_string);

    const wifi_data = get_wifi_data(hw.wifi_devices);

    const extender_list = get_extender_list(hw.extender_list);

    const conntrack = get_conntrack(hw.conntrack);

    const exit_info = get_exit_info(device.exit_con);

    const global_ipv6: string = get_global_ipv6(device.exit_con);

    return (
      <div>
        <Label>
          <b>Model:</b>
        </Label>
        <InputGroup>
          <Input id="routerModel" readOnly value={hw.model} />
        </InputGroup>
        <Label>
          <b>Cores:</b>
        </Label>
        <InputGroup>
          <Input id="routerCores" readOnly value={hw.logical_processors} />
        </InputGroup>
        <Label>
          <b>Uptime:</b>
        </Label>
        <InputGroup>
          <Input id="uptime" readOnly value={uptime_formatted} />
        </InputGroup>
        <Label>
          <b>Rita Uptime:</b>
        </Label>
        <InputGroup>
          <Input id="ritaUptime" readOnly value={rita_uptime_formatted} />
        </InputGroup>
        {exit_info}
        <Label>
          <b>Global Ipv6:</b>
        </Label>
        <InputGroup>
          <Input id="globalIpv6" readOnly value={global_ipv6} />
        </InputGroup>
        <Label>
          <b>Linux Kernel Version:</b>
        </Label>
        <InputGroup>
          <Input
            id="linuxKernelVersion"
            readOnly
            value={hw.system_kernel_version}
          />
        </InputGroup>
        <Label>
          <b>Linux Kernel Version (full string):</b>
        </Label>
        <InputGroup>
          <Input
            id="linuxKernelVersionFull"
            readOnly
            value={hw.entire_system_kernel_version}
          />
        </InputGroup>
        <Label></Label>
        {load_average_display(hw)}
        <Label>
          <b>Load Average (1/5/15 minute):</b>
        </Label>
        <InputGroup>
          <Input id="routerLoadAvg" readOnly value={load_avg_string} />
        </InputGroup>
        <Label>
          <b>Memory (used/available):</b>
        </Label>
        <InputGroup>
          <Input id="routerMemory" readOnly value={memory_string} />
        </InputGroup>
        {display_temperature(hw)}
        {sensors}
        <div>{ethernet}</div>
        <div>{wifi_data}</div>
        <div>{extender_list}</div>
        <div>{conntrack}</div>
      </div>
    );
  }
}
function make_temperature_bar(sensors: SensorReading) {
  const percent = Number(sensors.reading) / Number(sensors.max);
  const diff = Math.floor(Number(sensors.max) - Number(sensors.min));
  const green_percent = Math.floor(percent * diff);
  return <Progress value={green_percent * 100} />;
}
function display_temperature(hw: HardwareInfo) {
  if (!hw.sensor_readings) {
    return "Device is not reporting any sensors";
  } else {
    const obj = [];
    for (let i = 0; i < hw.sensor_readings.length; i++) {
      if (
        Number(hw.sensor_readings[i].min) === 0 ||
        Number(hw.sensor_readings[i].max) === 0
      ) {
        continue;
      }

      obj.push(
        <div>
          <b>Sensor {hw.sensor_readings[i].name}:</b>
          <div>
            <b>Min: {Number(hw.sensor_readings[i].min) + "°C"}</b>
            <b style={{ textAlign: "right" }}>
              Max: {Number(hw.sensor_readings[i].max) + "°C"}
            </b>
          </div>
          {make_temperature_bar(hw.sensor_readings[i])}
        </div>
      );
    }
    if (obj.length === 0) {
      return <></>;
    }
    return obj;
  }
}
function load_average_display(hw: HardwareInfo) {
  const all_loads = [];
  all_loads.push(
    <div>
      <Label>
        <b> (Load Average 1 minute/Cores):</b>
      </Label>
      <Progress
        value={(hw.load_avg_one_minute / hw.logical_processors) * 100}
        color={"danger"}
      />
    </div>
  );
  all_loads.push(
    <div>
      <Label>
        <b> (Load Average 5 minute/Cores):</b>
      </Label>
      <Progress
        value={(hw.load_avg_five_minute / hw.logical_processors) * 100}
        color={"danger"}
      />
    </div>
  );
  all_loads.push(
    <div>
      <Label>
        <b> (Load Average 15 minute/Cores):</b>
      </Label>
      <Progress
        value={(hw.load_avg_fifteen_minute / hw.logical_processors) * 100}
        color={"danger"}
      />
    </div>
  );
  return all_loads;
}

function get_wifi_data(devices: Array<WifiDevice>) {
  if (devices === null) {
    return;
  }
  const obj = [];
  const sur_obj = [];
  const stat_obj = [];
  for (let i = 0; i < devices.length; i++) {
    const name = devices[i].name;

    let prev_survey_noise, prev_survey_channel, prev_survey_busy_time;
    for (let j = 0; j < devices[i].survey_data.length; j++) {
      if (
        typeof prev_survey_noise !== "undefined" &&
        prev_survey_noise === devices[i].survey_data[j].noise_dbm &&
        prev_survey_channel ===
          (devices[i].survey_data[j].channel_active_time / 1000).toFixed(1) &&
        prev_survey_busy_time ===
          (devices[i].survey_data[j].channel_busy_time / 1000).toFixed(1)
      )
        continue;
      sur_obj.push(
        <div>
          <Label>
            <b>
              {readable_wifi(devices[i].survey_data[j].frequency_mhz) + " GHz"}{" "}
              wifi device/router with name: ({devices[i].name})
            </b>
          </Label>
        </div>
      );
      sur_obj.push(
        <div>
          <InputGroup>
            <Input
              id={name}
              readOnly
              value={"Noise: " + devices[i].survey_data[j].noise_dbm + " dBm"}
            />
            <Input
              id={name}
              readOnly
              value={
                "Active Time:" +
                (devices[i].survey_data[j].channel_active_time / 1000).toFixed(
                  1
                ) +
                " seconds"
              }
            />
            <Input
              id={name}
              readOnly
              value={
                "Busy Time:" +
                (devices[i].survey_data[j].channel_busy_time / 1000).toFixed(
                  1
                ) +
                " seconds"
              }
            />
          </InputGroup>
          <br />
        </div>
      );
      prev_survey_noise = devices[i].survey_data[j].noise_dbm;
      prev_survey_channel = (
        devices[i].survey_data[j].channel_active_time / 1000
      ).toFixed(1);
      prev_survey_busy_time = (
        devices[i].survey_data[j].channel_busy_time / 1000
      ).toFixed(1);
    }
    for (let j = 0; j < devices[i].station_data.length; j++) {
      stat_obj.push(
        <div>
          <InputGroup>
            <InputGroup>
              <Input
                id={name}
                readOnly
                value={"MAC address: " + devices[i].station_data[j].station}
              />
              <Input
                id={name}
                readOnly
                value={
                  "Device last checked in: " +
                  (devices[i].station_data[j].inactive_time_ms / 1000).toFixed(
                    1
                  ) +
                  "seconds"
                }
              />
            </InputGroup>
            <InputGroup>
              <Input
                id={name}
                readOnly
                value={
                  "Amount uploaded: " +
                  prettyBytes(devices[i].station_data[j].rx_bytes)
                }
              />
              <Input
                id={name}
                readOnly
                value={
                  "Amount downloaded: " +
                  prettyBytes(devices[i].station_data[j].tx_bytes)
                }
              />
            </InputGroup>
            <InputGroup>
              <Input
                id={name}
                readOnly
                value={
                  "Upload speed: " +
                  prettyBytes(devices[i].station_data[j].rx_bitrate)
                }
              />
              <Input
                id={name}
                readOnly
                value={
                  "Download speed: " +
                  prettyBytes(devices[i].station_data[j].tx_bitrate)
                }
              />
            </InputGroup>
            <InputGroup>
              <Input
                id={name}
                readOnly
                value={
                  "Upload packet retries: " +
                  devices[i].station_data[j].tx_retries
                }
              />
              <Input
                id={name}
                readOnly
                value={
                  "Download packet retries: " +
                  devices[i].station_data[j].tx_failed
                }
              />
            </InputGroup>
            <InputGroup>
              <Input
                id={name}
                readOnly
                value={"Signal: " + devices[i].station_data[j].signal_dbm}
              />
              <Input
                id={name}
                readOnly
                value={
                  "Signal Avg: " + devices[i].station_data[j].signal_avg_dbm
                }
              />
              <Input
                id={name}
                readOnly
                value={
                  "Connected Time: " +
                  devices[i].station_data[j].connected_time_sec +
                  " sec"
                }
              />
            </InputGroup>
            <Label>
              <b>Current time(UTC):</b>{" "}
              <i>
                {readable_unix_timestamp(
                  devices[i].station_data[j].current_time_ms
                )}
              </i>
            </Label>
          </InputGroup>
          <br />
        </div>
      );
    }
  }
  obj.push(
    <div>
      <Label>
        <b>All radios:</b>
      </Label>
      <div>{sur_obj}</div>
      <Label>
        <b>All devices:</b>
      </Label>
      <div>{stat_obj}</div>
    </div>
  );
  if (sur_obj.length === 0 && stat_obj.length === 0) {
    return (
      <div>
        <Label>No wifi data to display</Label>
      </div>
    );
  }
  return obj;
}

function get_extender_list(devices: Array<ClientExtender> | null) {
  if (devices == null || devices.length === 0) {
    return "Device is not reporting any extenders";
  }

  const ret = [];
  ret.push(
    <Label>
      <b>Extender List: </b>
    </Label>
  );
  for (let i = 0; i < devices.length; i++) {
    ret.push(<div> devices[i].mac_addr.toString(16) </div>);
  }
  return ret;
}

function prettyBytes(bytes: number) {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.min(
    parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10),
    sizes.length - 1
  );
  return `${(bytes / 1024 ** i).toFixed(i ? 1 : 0)}${sizes[i]}`;
}
function readable_wifi(frequency: number) {
  const wifi_freq = Math.round((frequency / 1000) * 10) / 10;
  return Math.floor(wifi_freq) % 5 === 0 ? 5 : wifi_freq.toFixed(1);
}
function readable_unix_timestamp(epoch_time: number) {
  return new Date(epoch_time * 1000).toLocaleTimeString("en-US");
}

function format_duration_string(duration: string) {
  const uptime_parsed: Duration = JSON.parse(duration);
  const seconds = uptime_parsed.secs;
  const month = Math.floor(seconds / (3600 * 24) / 30);
  const d = Math.floor((seconds / (3600 * 24)) % 30);
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);

  const monthDisplay =
    month > 0 ? month + (month === 1 ? " month, " : "months, ") : "";
  const dDisplay = d > 0 ? d + (d === 1 ? " day, " : " days, ") : "";
  const hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
  const mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
  const sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
  return monthDisplay + dDisplay + hDisplay + mDisplay + sDisplay;
}

function display_user_settings(device: DeviceEntryReturn) {
  let display;
  if (device.user_bandwidth_limit == null) {
    display = "No limit";
  } else {
    display = device.user_bandwidth_limit + " Mbps";
  }
  return (
    <div>
      <Label>
        <b>User Bandwidth Limit:</b>
      </Label>
      <InputGroup>
        <Input id="userBandwidthLimit" readOnly value={display} />
      </InputGroup>
    </div>
  );
}

interface RouterInfoProps {
  device_to_view: any;
}

const RouterInfo: React.FC<RouterInfoProps> = (props) => {
  const gnosis =
    "https://gnosisscan.io/address/" + props.device_to_view.id.eth_address;
  const etherscan =
    "https://etherscan.io/address/" + props.device_to_view.id.eth_address;
  const blockscout =
    "https://gnosis.blockscout.com/address/" +
    props.device_to_view.id.eth_address +
    "?tab=coin_balance_history#address-tabs";

  const id = props.device_to_view.id;
  const meshIp = id.mesh_ip;
  const address = id.eth_address;
  const wgPublicKey = id.wg_public_key;

  return (
    <div>
      <Label>
        <b>Mesh Ip:</b>
      </Label>
      <InputGroup>
        <Input id="meshIP" readOnly value={meshIp} />
      </InputGroup>
      <Label>
        <b>Payment Address:</b>
      </Label>
      <InputGroup>
        <Input id="ethAddr" readOnly value={address} />
        <InputGroupAddon addonType="append">
          <Button onClick={() => window.open(gnosis, "_blank")}>
            GnosisScan
          </Button>
        </InputGroupAddon>
        <InputGroupAddon addonType="append">
          <Button onClick={() => window.open(blockscout, "_blank")}>
            Blockscout
          </Button>
        </InputGroupAddon>
        <InputGroupAddon addonType="append">
          <Button onClick={() => window.open(etherscan, "_blank")}>
            Etherscan
          </Button>
        </InputGroupAddon>
      </InputGroup>
      <Label>
        <b>WireGuard Public Key</b>
      </Label>
      <InputGroup>
        <Input id="wgPubKey" readOnly value={wgPublicKey} />
      </InputGroup>
    </div>
  );
};

interface RouterDetailsModalProps {
  device_to_view: DeviceEntryReturn;
  is_open: boolean;
  our_permissions: any;
  set_wifi_actions_modal: boolean;
  toggle: Function;
  epc_list: any;
  submit_epc_addr: Function;
  submit_operator_action: Function;
  submit_device_network: Function;
  operator_action_buttons: Function;
  super_user_action_buttons: Function;
  toggle_set_wifi_actions: Function;
  error: any;
  success: any;
}

const RouterDetailsModal: React.FC<RouterDetailsModalProps> = (props) => {
  const [newOperatorAddress, setNewOperatorAddress] =
    useState<string>("0xNewAddressHere");
  const [update_auth_keys, setUpdateSshKeys] =
    useState<string>("ssh-public-key");
  const [wifiModalOpen, setWifiModalOpen] = useState<boolean>(false);

  const toggleWifiModalOpen = () => {
    setWifiModalOpen(!wifiModalOpen);
  };
  const triggerWifiModalOpen = () => {
    setWifiModalOpen(true);
  };
  const [EPCAddr, setEPCAddr] = useState<string>("");
  const epcList: EPCNameTags[] = props.epc_list;

  // reload these values when the device changes so we don't load the modal with previously entered values
  useEffect(() => {
    setNewOperatorAddress("0xNewAddressHere");
    setUpdateSshKeys("ssh-public-key");
  }, [props.device_to_view]);
  if (!props.device_to_view) {
    return <></>;
  }

  const device = props.device_to_view;

  let name;
  if (device.name) {
    name = device.name;
  } else {
    name = device.id.wg_public_key;
  }

  const sup_action: SuperUserOperatorActionProps = {
    device_to_view: [device],
    our_permissions: props.our_permissions,
    submit_operator_action: props.submit_operator_action,
    set_update_auth_keys: setUpdateSshKeys,
    update_auth_keys: update_auth_keys,
  };
  let superuserButtons = <></>;
  if (props.our_permissions && we_are_superuser(props.our_permissions)) {
    superuserButtons = (
      <>
        <hr />
        <Container>
          {props.super_user_action_buttons(sup_action, false)}
        </Container>
      </>
    );
  }

  let tower;
  if (device.extra_data?.type === "TowerInfo") {
    const sgwu_start_time: Date = new Date(
      device.extra_data.sgwu_start_time.secs * 1000
    );
    const upf_start_time: Date = new Date(
      device.extra_data.upf_start_time.secs * 1000
    );
    const tac_id: number = device.extra_data.tac_id;
    const subnet: string = device.extra_data.subnet;
    let epc_addr: string = device.extra_data.epc_addr;
    const allowed_epcs = epcList;
    let cpe_ips = "";
    let cpe_count = 0;
    if (device.extra_data.cpe_ips) {
      for (const i in device.extra_data.cpe_ips) {
        cpe_ips += device.extra_data.cpe_ips[i].toString() + ",  ";
        cpe_count++;
      }
    }
    const current_epc = allowed_epcs.filter(
      (option) => option.address === epc_addr
    );

    tower = (
      <div>
        <hr />
        <Container>
          <Label>
            <b>SGWU Start Time</b>
          </Label>
          <InputGroup>
            <Input id="sgwu" readOnly value={sgwu_start_time.toString()} />
          </InputGroup>
          <Label>
            <b>UPF Start Time</b>
          </Label>
          <InputGroup>
            <Input id="upf" readOnly value={upf_start_time.toString()} />
          </InputGroup>
          <Label>
            <b>TAC ID</b>
          </Label>
          <InputGroup>
            <Input id="tac_id" readOnly value={tac_id.toString()} />
          </InputGroup>
          <Label>
            <b>eNodeB Subnet</b>
          </Label>
          <InputGroup>
            <Input id="subnet" readOnly value={subnet} />
          </InputGroup>
          <Label>
            <b>Number of CPEs</b>
          </Label>
          <InputGroup>
            <Input id="subnet" readOnly value={cpe_count} />
          </InputGroup>
          <Label>
            <b>CPE IPs</b>
          </Label>
          <InputGroup>
            <Input id="subnet" readOnly value={cpe_ips} />
          </InputGroup>
          <Label>
            <b>EPC Address</b>
          </Label>
          <InputGroup>
            <Input
              type="select"
              onChange={(e) => {
                epc_addr = String(e.target.value);
                console.log("setting epc address to " + epc_addr);
                setEPCAddr(epc_addr);
              }}
              defaultValue={current_epc[0].tag}
            >
              {allowed_epcs.map((item) => {
                return (
                  <option key={item.tag} value={item.tag}>
                    {item.tag}
                  </option>
                );
              })}
            </Input>

            <Button
              style={{
                marginLeft: 5,
              }}
              onClick={async () => {
                await props.submit_epc_addr(EPCAddr, device.id);
              }}
            >
              Edit EPC Address
            </Button>
          </InputGroup>
        </Container>
      </div>
    );
  }

  const op_action: OperatorActionProps = {
    devices_to_view: [device],
    our_permissions: props.our_permissions,
    submit_operator_action: props.submit_operator_action,
    set_wifi_actions_toggle: props.toggle_set_wifi_actions,
    new_operator_address: newOperatorAddress,
    set_new_operator_address: setNewOperatorAddress,
    submit_device_network: props.submit_device_network,
  };

  return (
    <>
      {props.error}
      {props.success}
      <Modal
        open={props.is_open}
        onClose={() => props.toggle()}
        style={{ overflowX: "scroll" }}
      >
        <Grid container justifyContent="center">
          <Grid
            item
            sm={12}
            md={12}
            lg={6}
            justifyContent="center"
            style={{ overflowX: "scroll" }}
            display="flow"
          >
            <MDBox px={2} py={5} textAlign="center" justifyContent="center">
              <Card>
                <ModalHeader toggle={() => props.toggle()}>
                  Router Details: {name}
                </ModalHeader>

                <ModalBody>
                  <Container>{display_version_formatted(device)}</Container>
                  <hr />
                  <Container>
                    <RouterInfo device_to_view={device} />
                  </Container>
                  <hr />
                  <SetWifiModal
                    is_open={props.set_wifi_actions_modal}
                    device={device}
                    toggle={props.toggle_set_wifi_actions}
                    submit_operator_action={props.submit_operator_action}
                  />
                  <Container>
                    {props.operator_action_buttons(op_action, false)}
                  </Container>
                  {superuserButtons}
                  <hr />
                  <WifiModal
                    is_open={wifiModalOpen}
                    toggle={toggleWifiModalOpen}
                    hw={device.last_hardware_info}
                  />

                  <Button
                    style={{
                      marginLeft: 5,
                    }}
                    onClick={() => triggerWifiModalOpen()}
                  >
                    User friendly hardware View
                  </Button>
                  <Container>{display_hardware(device)}</Container>
                  <hr />
                  <Container>{display_user_settings(device)}</Container>
                  {tower}
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={() => props.toggle()}>
                    close
                  </Button>
                </ModalFooter>
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default RouterDetailsModal;
