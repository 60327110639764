/*
=========================================================
This is the dashboard the user sees after logging in, which shows a
grid of NetworkCards which each show a bit of information about an 
individual network. 
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";

// Anaytics dashboard components
import { Spinner } from "reactstrap";
import { NetworkEntrySummary } from "types";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import { wei_to_eth } from "utils";
import NetworkCard from "examples/Cards/InfoCards/NetworkCard";
import { Card } from "@mui/material";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

/// This returns a list of networks to display, and add the unattached/all devices networks if permissions allow
function NetworksList(
  networks_list: any,
  permissions: any,
  unattached_router_summary: any
) {
  // maybe an extra row for the super user summary list
  // eslint-disable-next-line prefer-const
  let networksList = [];
  networks_list.map(
    (network: {
      name: any;
      address: any;
      device_count: any;
      offline_device_count: any;
      active_device_count: any;
      average_balance: number;
    }) => {
      networksList.push({
        name: network.name,
        address: network.address,
        online: network.device_count,
        offline: network.offline_device_count,
        active: network.active_device_count ? network.active_device_count : "-",
        balance: wei_to_eth(network.average_balance).toFixed(2),
        permissions: permissions,
      });
    }
  );
  if (
    String(permissions) === String("SuperUser") &&
    unattached_router_summary
  ) {
    networksList.push({
      name: "Unattached Routers",
      address: "unattached",
      online: unattached_router_summary.device_count,
      offline: unattached_router_summary.offline_device_count,
      active: unattached_router_summary.active_device_count
        ? unattached_router_summary.active_device_count
        : "-",
      balance: wei_to_eth(unattached_router_summary.average_balance).toFixed(2),
      permissions: permissions,
    });
  }
  networksList.push({
    name: "All Networks",
    address: "devices",
    online: "",
    offline: "",
    active: "",
    balance: null,
    permissions: "",
  });
  return networksList;
}

function get_active_inactive(net_list: Array<NetworkEntrySummary>) {
  if (net_list == null) {
    return "No Data";
  }

  let total_active = 0;
  let total_online = 0;
  let total_offline = 0;

  for (let i = 0; i < net_list.length; i++) {
    total_active += net_list[i].active_device_count;
    total_online += net_list[i].device_count;
    total_offline += net_list[i].offline_device_count;
  }

  return total_active.toString() + " / " + total_online.toString() + " / " + total_offline.toString();
}

interface DashboardLandingProps {
  networks?: any | undefined;
  delete_network: Function;
  trigger_name_modal: Function;
  trigger_overview_modal: Function;
  permissions: any;
  unattached_router_summary: any;
}

function DashboardLanding({
  networks,
  delete_network,
  permissions,
  unattached_router_summary,
  trigger_name_modal,
  trigger_overview_modal,
}: DashboardLandingProps): JSX.Element {
  let networksList;
  let returnlist = <Spinner color="primary" />;
  if (networks) {
    const nlist = NetworksList(
      networks,
      permissions,
      unattached_router_summary
    );
    networksList = nlist;
  }
  if (networksList) {
    returnlist = (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {networksList.map((network) => (
          <NetworkCard
            network={network}
            name={network.name}
            address={network.address}
            active={network.active}
            online={network.online}
            offline={network.offline}
            trigger_name_modal={trigger_name_modal}
            trigger_overview_modal={trigger_overview_modal}
            delete_network={delete_network}
            permissions={permissions}
            balance={network.balance}
          />
        ))}
      </div>
    );
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={2} mb={2}>
        <Card sx={{ width: "100%" }}>
          <MDBox p={2} mx={3} display="flex" justifyContent="left">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="success"
              color="white"
              shadow="md"
              borderRadius="xl"
              mt={-5}
            >
              <Icon>router</Icon>
            </MDBox>
          </MDBox>
          <MDBox pl={5} pb={3} pt={1} textAlign="left" lineHeight={1.25}>
            <MDTypography variant="h4" fontWeight="medium">
              All Networks View
            </MDTypography>
            <div></div>
            Total Active / Online / Offline : {get_active_inactive(networks)}
          </MDBox>
        </Card>
        <Grid
          my={3}
          ml={2}
          pr={3}
          container
          spacing={3}
          width="100%"
          flexDirection={{ xs: "column", sm: "column" }}
        >
          {returnlist}
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DashboardLanding;
