import React, { useEffect } from "react";
import {
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  Input,
} from "reactstrap";
import { useState } from "react";
import { DeviceEntryReturn } from "../types";
import { Card, Grid, Modal } from "@mui/material";
import MDBox from "components/MDBox";

interface CommentsModalProps {
  device_to_edit: DeviceEntryReturn;
  is_open: boolean;
  delete_device: Function;
  toggle: Function;
  submit_name: Function;
  submit_comments: Function;
  submit_install_details: Function;
  submit_splynx_id: Function;
}

const CommentsModal: React.FC<CommentsModalProps> = (props) => {
  const device = props.device_to_edit;
  const [comments, setComments] = useState<string | null>(null);
  let local_comments: string;
  if (device && device.comments !== null && comments === null) {
    local_comments = device.comments;
  } else if (comments !== null) {
    local_comments = comments;
  } else {
    local_comments = "";
  }
  const [name, setName] = useState<string | null>(null);
  let local_name: string;
  if (device && device.name !== null && name === null) {
    local_name = device.name;
  } else if (name !== null) {
    local_name = name;
  } else {
    local_name = "";
  }
  const [clientIP, setClientIP] = useState<string | null>(null);
  let client_antenna_ip: string;
  if (
    device &&
    device.install_details !== null &&
    device.install_details.client_antenna_ip !== null &&
    clientIP === null
  ) {
    client_antenna_ip = device.install_details.client_antenna_ip;
  } else if (clientIP !== null) {
    client_antenna_ip = clientIP;
  } else {
    client_antenna_ip = "";
  }
  const [relayAntennas, setRelayAntennas] = useState<string | null>(null);
  let relay_antennas: string;
  if (device && device.install_details !== null && relayAntennas === null) {
    relay_antennas = device.install_details.relay_antennas.toString();
  } else if (relayAntennas !== null) {
    relay_antennas = relayAntennas;
  } else {
    relay_antennas = "";
  }
  const [physicalAddress, setPhysicalAddress] = useState<string | null>(null);
  let phys_address: string;
  if (
    device &&
    device.install_details !== null &&
    device.install_details.physical_address !== null &&
    physicalAddress === null
  ) {
    phys_address = device.install_details.physical_address;
  } else if (physicalAddress !== null) {
    phys_address = physicalAddress;
  } else {
    phys_address = "";
  }
  const [equipmentDetails, setEquipmentDetails] = useState<string | null>(null);
  let equipment_details: string;
  if (device && device.install_details !== null && equipmentDetails === null) {
    equipment_details = device.install_details.equipment_details;
  } else if (equipmentDetails !== null) {
    equipment_details = equipmentDetails;
  } else {
    equipment_details = "";
  }
  const [splynxId, setSplynxId] = useState<number | null>(null);
  let splynx_id: number | null;
  if (device && device.splynx_id && !splynxId) {
    splynx_id = device.splynx_id;
  } else {
    splynx_id = splynxId;
  }
  let install_date: string;
  if (
    device &&
    device.install_details !== null &&
    device.install_details.install_date !== null
  ) {
    install_date = device.install_details.install_date;
  } else {
    install_date = "";
  }
  // reloads these values when the device_to_edit changes
  useEffect(() => {
    setSplynxId(null);
    setComments(null);
    setName(null);
    setRelayAntennas(null);
    setEquipmentDetails(null);
    setPhysicalAddress(null);
    setClientIP(null);
  }, [props.device_to_edit]);

  return (
    <Modal
      open={props.is_open}
      onClose={() => props.toggle()}
      style={{ overflowX: "scroll" }}
    >
      <Grid container justifyContent="center">
        <Grid
          item
          sm={12}
          md={12}
          lg={6}
          justifyContent="center"
          style={{ overflowX: "scroll" }}
          display="flow"
        >
          <MDBox px={2} py={5} textAlign="center" justifyContent="center">
            <Card>
              <ModalHeader toggle={() => props.toggle()}>
                Edit Name And Comments
              </ModalHeader>
              <ModalBody>
                <InputGroup>
                  <Input
                    type="text"
                    value={local_name}
                    placeholder={local_name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </InputGroup>
                <hr />
                <InputGroup>
                  <Input
                    type="textarea"
                    value={local_comments}
                    placeholder={local_comments}
                    onChange={(e) => setComments(e.target.value)}
                  />
                </InputGroup>
                <hr />
              </ModalBody>
              <ModalHeader>Installation Details</ModalHeader>
              <ModalBody>
                <label>Client Antenna IP</label>
                <InputGroup>
                  <Input
                    type="text"
                    value={client_antenna_ip}
                    placeholder={client_antenna_ip}
                    onChange={(e) => setClientIP(e.target.value)}
                  />
                </InputGroup>
                <label>Relay Antennas</label>
                <InputGroup>
                  <Input
                    type="text"
                    value={relay_antennas}
                    placeholder={relay_antennas}
                    onChange={(e) => setRelayAntennas(e.target.value)}
                  />
                </InputGroup>
                <label>Physical Address</label>
                <InputGroup>
                  <Input
                    type="text"
                    value={phys_address}
                    placeholder={phys_address}
                    onChange={(e) => setPhysicalAddress(e.target.value)}
                  />
                </InputGroup>
                <label>Equipment Details</label>
                <InputGroup>
                  <Input
                    type="text"
                    value={equipment_details}
                    placeholder={equipment_details}
                    onChange={(e) => setEquipmentDetails(e.target.value)}
                  />
                </InputGroup>
                <label>Install Date</label>
                <InputGroup>
                  <Input type="text" value={install_date} readOnly />
                </InputGroup>
                <label>Splynx ID (Compudopt Only)</label>
                <InputGroup>
                  <Input
                    type="text"
                    value={splynx_id}
                    placeholder=""
                    onChange={(e) => {
                      if (isNaN(Number(e.target.value))) {
                        setSplynxId(null);
                      } else {
                        setSplynxId(Number(e.target.value));
                      }
                    }}
                  />
                </InputGroup>
                <ModalBody></ModalBody>
                <label></label>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  onClick={async () => {
                    if (local_name != device.name?.toString()) {
                      await props.submit_name(local_name, device.id);
                    }
                    if (local_comments != device.comments?.toString()) {
                      await props.submit_comments(local_comments, device.id);
                    }
                    // check if the install details have been changed. we must check
                    // if the value is "" vs if the given is null.
                    if (
                      !(
                        client_antenna_ip ==
                          (device.install_details?.client_antenna_ip
                            ? device.install_details.client_antenna_ip.toString()
                            : "") &&
                        relay_antennas ==
                          (device.install_details?.relay_antennas
                            ? device.install_details.relay_antennas.toString()
                            : "") &&
                        phys_address ==
                          (device.install_details?.physical_address
                            ? device.install_details.physical_address
                            : "") &&
                        equipment_details ==
                          (device.install_details?.equipment_details
                            ? device.install_details.equipment_details
                            : "")
                      )
                    ) {
                      await props.submit_install_details(
                        client_antenna_ip,
                        relay_antennas,
                        phys_address,
                        equipment_details,
                        device.id
                      );
                    }
                    if (splynx_id?.toString() != device.splynx_id?.toString()) {
                      await props.submit_splynx_id(splynx_id, device.id);
                    }
                    props.toggle();
                    setComments(null);
                    setName(null);
                    setClientIP(null);
                    setRelayAntennas(null);
                    setPhysicalAddress(null);
                    setEquipmentDetails(null);
                    setSplynxId(null);
                  }}
                >
                  Save
                </Button>{" "}
                <Button
                  color="secondary"
                  onClick={() => {
                    setComments(null);
                    setName(null);
                    props.toggle();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  color="warning"
                  onClick={() => {
                    if (
                      confirm("Are you sure you want to delete this device?")
                    ) {
                      props.delete_device(device.id);
                      props.toggle();
                    }
                  }}
                >
                  {" "}
                  Delete This Router
                </Button>
              </ModalFooter>
            </Card>
          </MDBox>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default CommentsModal;
